<template>
  <v-navigation-drawer
    v-model="show"
    right
    fixed
    stateless
    width="650"
    temporary
    touchless
    :overlay-opacity="0.3"
    class="white--text content-scroll"
  >
    <template #prepend>
      <v-list-item class="grey lighten-5">
        <v-list-item-content>
          <v-list-item-title
            class="font-weight-bold display-1 primary--text"
            v-text="'Registrar Cobro'"
          />
          <v-list-item-subtitle class="blue-grey--text font-italic wrap caption">
            Ingrese todos los datos necesarios para el registro en nuestro sistema.
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="ml-1 d-flex align-start justify-end">
          <v-btn
            icon
            small
            color="blue-grey"
            fab
            @click="toBack"
          >
            <v-icon size="22">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-card flat tile class="fill-height">
      <v-card-text class="pa-0">
        <v-stepper
          v-model="stepsForm"
          flat
          class="elevation-0"
        >
          <v-stepper-header
            class="elevation-0 grey lighten-5 steps-header"
          >
            <v-stepper-step
              step="1"
              color="icono"
              class="py-1"
              :complete="stepsForm > 1"
              :rules="[() => errorsForm[0].valid, () => !cli_not_facts]"
            >
              Transacción
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="2"
              color="icono"
              class="py-1"
              :complete="stepsForm > 2"
              :rules="[() => errorsForm[1].valid]"
            >
              Detalles
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="3"
              color="icono"
              class="py-1"
            >
              Confirmación
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items
            class="elevation-0 pt-3"
          >
            <v-stepper-content
              step="1"
              class="pt-1 step-item"
            >
              <validation-observer ref="COBRO_REGISTER_FORM_1" tag="div">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex flex-column pt-1"
                  >
                    <span class="text-muted caption font-italic">Paso 1/3</span>
                    <span class="text-h4 font-weight-bold primary--text mt-n1">Tipo de Trasacción</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-if="cli_not_facts"
                    cols="12"
                    class="pb-0"
                  >
                    <v-alert
                      text
                      prominent
                      type="error"
                      icon="mdi-alert"
                    >
                      El cliente seleccionado No Posee facturas registradas en nuestro sistema, por tal motivo no puede continuar con registro. Seleccione otro cliente para continuar.
                    </v-alert>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex flex-column pb-0"
                  >
                    <span class="caption blue-grey--text text--darken-3 mb-1">
                      <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-account-tie</v-icon>
                      Cliente
                      <span class="secondary--text font-weight-bold" v-text="'*'" />
                    </span>
                    <validation-provider
                      name="Clientes"
                      vid="cobro.co_cli"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-model="cobro.co_cli"
                        background-color="grey lighten-5"
                        :items="cli"
                        item-text="cli_des"
                        item-value="co_cli"
                        :placeholder="loadInfo ? 'Cargando lista de Clientes' :'Seleccione'"
                        outlined
                        dense
                        :hint="cli.length === 0 && !loadInfo ? 'Presione el icono para actualizar la lista de Clientes' : ''"
                        persistent-hint
                        :loading="loadInfo || loadingFacts"
                        :disabled="loadInfo"
                        :error-messages="errors"
                        @change="getFactClientes"
                      >
                        <template #append-outer>
                          <v-btn
                            v-if="!loadInfo && cli.length === 0"
                            icon
                            small
                            @click="getInfo"
                          >
                            <v-icon color="primary">mdi-update</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item="{item}">
                          <v-list-item-icon class="mr-2">
                            <v-icon>mdi-account-tie</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-html="item.cli_des" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex"
                  >
                    <div class="d-flex flex-column">
                      <span class="caption blue-grey--text text--darken-3 mb-1">
                        <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-credit-card-scan</v-icon>
                        Moneda
                        <span class="secondary--text font-weight-bold" v-text="'*'" />
                      </span>
                      <validation-provider
                        name="Moneda"
                        rules="required"
                        vid="cobro.moneda"
                        v-slot="{ errors }"
                      >
                        <v-btn-toggle
                          v-model="cobro.moneda"
                          :value="''"
                          color="primary"
                          class="toggle-registre"
                          active-class="full-opacity elevation-2"
                          :background-color="errors[0] ? 'red lighten-5' : 'blue-grey lighten-5'"
                          borderless
                          @change="changeMoneda"
                        >
                          <v-btn
                            value="dolares"
                            :class="{'px-8': $vuetify.breakpoint.mdAndUp}"
                            :color="errors[0] ? 'red lighten-5' : 'blue-grey lighten-5' "
                          >
                            <span>Dolares Americanos</span>
                          </v-btn>
                          <v-divider vertical />
                          <v-btn
                            value="bolivares"
                            :class="{'px-16': $vuetify.breakpoint.mdAndUp}"
                            :color="errors[0] ? 'red lighten-5' : 'blue-grey lighten-5' "
                          >
                            <span>Bolivares</span>
                          </v-btn>
                        </v-btn-toggle>
                        <span class="pt-1 red--text v-messages v-messages__message d-block" v-text="errors[0]" />
                      </validation-provider>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-start pt-1"
                  >
                    <div class="d-flex flex-column" style="width:100%">
                      <span class="caption blue-grey--text text--darken-3 mb-1">
                        <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-credit-card-scan</v-icon>
                        Método Pago
                        <span class="secondary--text font-weight-bold" v-text="'*'" />
                      </span>
                      <validation-provider
                        name="Método"
                        rules="required"
                        vid="cobro.metodo_pago"
                        v-slot="{ errors }"
                      >
                        <v-chip-group
                          v-model="cobro.metodo_pago"
                          active-class="icono"
                          color="primary"
                          class="status"
                          style="width:100%"
                          @change="changeMetodo"
                        >
                          <div class="d-flex justify-space-between" style="width:100%">
                            <v-chip
                              filter
                              outlined
                              label
                              class="width-chip justify-center mx-0"
                              :color="errors[0] ? 'red' : 'icono'"
                              x-large
                              value="transferencia"
                            >
                              <v-avatar
                                tile
                                left
                                size="80"
                              >
                                <v-img
                                  :src="require('@/assets/TRANSFERENCIA-ICONO.png')"
                                  :lazy-src="require('@/assets/TRANSFERENCIA-ICONO.png')"
                                  alt="TRANSFERENCIA-ICONO"
                                  width="90"
                                />
                              </v-avatar>
                              <span class="">Transferencia</span>
                            </v-chip>
                            <v-chip
                              filter
                              outlined
                              label
                              class="width-chip justify-center mx-0"
                              :color="errors[0] ? 'red' : 'icono'"
                              x-large
                              value="efectivo"
                            >
                              <v-avatar
                                tile
                                left
                                size="80"
                              >
                                <v-img
                                  :src="require('@/assets/EFECTIVO-ICONO.png')"
                                  :lazy-src="require('@/assets/EFECTIVO-ICONO.png')"
                                  alt="EFECTIVO-ICONO"
                                  width="90"
                                />
                              </v-avatar>
                              <span class="">Efectivo</span>
                            </v-chip>
                          </div>
                        </v-chip-group>
                        <span class="pt-1 red--text v-messages v-messages__message d-block" v-text="errors[0]" />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
              </validation-observer>
            </v-stepper-content>
            <v-stepper-content
              step="2"
              class="pt-1 step-item"
            >
              <validation-observer ref="COBRO_REGISTER_FORM_2" tag="div">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pt-1"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-muted caption font-italic">Paso 2/3</span>
                      <span class="text-h4 font-weight-bold primary--text mt-n1">Detalles del Cobro</span>
                    </div>
                    <div class="d-flex flex-column align-end justify-end">
                      <span
                        class="text-capitalize subtitle font-weight-bold primary--text"
                        v-text="cobro.metodo_pago"
                      />
                      <span
                        class="text-capitalize primary--text caption font-italic mt-n1"
                         v-text="cobro.moneda"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    :sm="cobro.metodo_pago === 'efectivo' && cobro.moneda === 'dolares' ? '8' : '12' "
                    class="d-flex flex-column pb-0 pt-1"
                  >
                    <span class="caption blue-grey--text text--darken-3 mb-1">
                      <v-icon small color="blue-grey" class="mr-1 mt-n1">
                        mdi-text-box-check-outline
                      </v-icon>
                      Facturas
                      <span class="secondary--text font-weight-bold" v-text="'*'" />
                    </span>
                    <validation-provider
                      v-slot="{ errors }"
                      ref="FACT"
                      name="Facturas"
                      rules="required"
                      vid="cobro.fact"
                    >
                      <v-autocomplete
                        v-model="cobro.fact"
                        background-color="grey lighten-5"
                        :items="itemsFact"
                        item-text="nro_doc"
                        item-value="nro_doc"
                        placeholder="Seleccione"
                        multiple
                        chips
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        :error-messages="errors"
                      >
                        <template v-slot:item="{item}">
                          <v-list-item-icon class="mr-2">
                            <v-icon>mdi-text-box-check-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-html="item.nro_doc" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                      <!-- <v-text-field
                        v-model="inputFact"
                        background-color="grey lighten-5"
                        outlined
                        dense
                        placeholder="Ingrese la factura"
                        hint="Presione enter para agregar el Nro de factura"
                        class="input-multiple"
                        persistent-hint
                        :error-messages="errors"
                        @keyup.enter="addFactura"
                      >
                        <template #prepend-inner>
                          <v-chip
                            v-for="(f,index) in cobro.fact"
                            :key="'inputFact-'+index"
                            small
                            close
                            class="mr-1"
                            @click:close="deleteFactura(index)"
                            @click="editFactuta(f,index)"
                          >
                            {{ f }}
                          </v-chip>
                        </template>
                      </v-text-field> -->
                    </validation-provider>
                  </v-col>
                  <template v-if="cobro.metodo_pago === 'transferencia'">
                    <v-col
                      cols="12"
                      :sm="cobro.moneda === 'bolivares' ? '6' : '12'"
                      class="d-flex flex-column pb-0 pt-1"
                    >
                      <span class="caption blue-grey--text text--darken-3 mb-1">
                        <v-icon small color="blue-grey" class="mr-1 mt-n1">
                          mdi-bank
                        </v-icon>
                        Banco
                      </span>
                      <validation-provider
                        name="Banco"
                        vid="cobro.cod_banco"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          v-model="cobro.cod_banco"
                          :placeholder="loadInfo ? 'Cargando lista de Bancos' :'Seleccione'"
                          outlined
                          dense
                          background-color="grey lighten-5"
                          item-text="nombre"
                          item-value="cod_banco"
                          :hint="bank.length === 0 && !loadInfo ? 'Presione el icono para actualizar la lista de Bancos' : ''"
                          persistent-hint
                          :loading="loadInfo"
                          :disabled="loadInfo"
                          :items="itemsBancos"
                          :error-messages="errors"
                          :no-data-text="bank.length === 0 ? 'No existen registros de Bancos.' : 'Seleccione una moneda para desplegar el listado de Bancos.' "
                        >
                          <template #append-outer>
                            <v-btn
                              v-if="!loadInfo && bank.length === 0"
                              icon
                              small
                              @click="getInfo"
                            >
                              <v-icon color="primary">mdi-update</v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item="{item}">
                            <v-list-item-icon class="mr-2">
                              <v-icon>mdi-bank</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title v-html="item.nombre"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="d-flex flex-column pb-0 pt-1"
                    >
                      <span class="caption blue-grey--text text--darken-3 mb-1">
                        <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-note-text-outline</v-icon>
                        Nro. Referencia:
                      </span>
                      <validation-provider
                        name="Nro Referencia"
                        vid="cobro.ref"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="cobro.ref"
                          placeholder="Referencia bancaria"
                          outlined
                          dense
                          background-color="grey lighten-5"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </template>
                  <v-col
                    cols="12"
                    :sm="cobro.metodo_pago === 'efectivo' && cobro.moneda === 'dolares' ? '4' : '6' "
                    class="d-flex flex-column pb-0 pt-1"
                  >
                    <span class="caption blue-grey--text text--darken-3 mb-1">
                      <v-icon small color="blue-grey" class="mr-1 mt-n1">
                        mdi-calendar-month
                      </v-icon>
                      Fecha de Cobro
                      <span class="secondary--text font-weight-bold" v-text="'*'" />
                    </span>
                    <validation-provider
                      name="Fecha Cobro"
                      vid="cobro.fecha"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <fecha-input
                        v-model="cobro.fecha"
                        :color="errors[0] ? 'red' : 'line-input'"
                        outlined
                        :max="maxDate"
                        :error="errors[0]"
                        :nudge-left="10"
                        width-input="100%"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    v-if="cobro.moneda === 'bolivares'"
                    cols="12"
                    sm="6"
                    class="d-flex flex-column pb-0 pt-1"
                  >
                    <span class="caption blue-grey--text text--darken-3 mb-1">
                      <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-percent-outline</v-icon>
                      Tasa del día
                    </span>
                    <validation-provider
                      name="Tasa del día"
                      vid="cobro.tasa"
                      :rules="{required:true, regex: /^[0-9]+([,][0-9]+)?$/ }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="cobro.tasa"
                        placeholder="Tasa de divisa"
                        background-color="grey lighten-5"
                        prefix="Bs. "
                        outlined
                        dense
                        :disabled="cobro.moneda === 'dolares'"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex flex-column pb-0 pt-1"
                  >
                    <span class="caption blue-grey--text text--darken-3 mb-1">
                      <v-icon small color="blue-grey" class="mr-1 mt-n1">
                        mdi-calculator
                      </v-icon>
                      Monto
                      <span class="secondary--text font-weight-bold" v-text="'*'" />
                    </span>
                    <validation-provider
                      v-if="cobro.moneda === 'dolares'"
                      v-slot="{ errors }"
                      name="Monto"
                      :rules="{required:true, regex: /^[0-9]+([,][0-9]+)?$/ }"
                      vid="cobro.monto"
                    >
                      <v-text-field
                        v-model="cobro.monto"
                        background-color="grey lighten-5"
                        outlined
                        dense
                        type="text"
                        placeholder="Ingrese el Monto"
                        persistent-hint
                        :error-messages="errors"
                      />
                    </validation-provider>
                    <validation-provider
                      v-if="cobro.moneda === 'bolivares'"
                      v-slot="{ errors }"
                      name="Monto en Bs."
                      :rules="{required:true, regex: /^[0-9]+([,][0-9]+)?$/ }"
                      vid="cobro.monto_bs"
                    >
                      <v-text-field
                        v-model="cobro.monto_bs"
                        background-color="grey lighten-5"
                        outlined
                        dense
                        counter
                        prefix="Bs. "
                        type="text"
                        placeholder="Ingrese el Monto"
                        persistent-hint
                        :error-messages="errors"
                      >
                        <template v-slot:counter="{ value }">
                          <span
                            class="primary--text font-weight-bold"
                          >
                            Divisa: {{ montoDolares | FormatCurrency }}$
                          </span>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="mt-1">
                  <v-col
                    cols="12"
                    class="d-flex flex-column pb-0"
                  >
                    <span class="caption blue-grey--text text--darken-3 mb-1">
                      <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-file-document-edit-outline</v-icon>
                      Observación
                    </span>
                    <validation-provider
                      name="Observación"
                      vid="cobro.observacion"
                      v-slot="{ errors }"
                    >
                      <v-textarea
                        v-model="cobro.observacion"
                        background-color="grey lighten-5"
                        placeholder="Escriba su observación"
                        outlined
                        dense
                        rows="4"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0 pt-1"
                  >
                    <span class="caption blue-grey--text text--darken-3 mb-1">
                      <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-camera-enhance-outline</v-icon>
                      Fotos
                    </span>
                    <VueFileAgent
                      ref="FileImageCobro"
                      v-model="cobro.fotos"
                      help-text="Suba la(s) imagen(s) en formato JPEG/JPG"
                      :accept="'.jpeg,.jpg'"
                      :multiple="true"
                      deletable
                      :theme="'list'"
                      :error-text="{type: 'Formato no válido.'}"
                      class="py-2 input-imagen"
                      @beforedelete="fileDeleted($event)"
                    />
                  </v-col>
                </v-row>
              </validation-observer>
            </v-stepper-content>
            <v-stepper-content
              step="3"
              class="pt-1 step-item"
            >
              <confirmacion-info
                :info="{
                  ...cobro,
                  montoDolares: montoDolares,
                  cli_des: descripcionSelect.cli_des,
                  banco_des: descripcionSelect.banco_des,
                }"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
    <template #append>
      <v-divider />
      <v-row justify="space-between" class="my-1 px-4">
        <v-col cols="6" sm="4" md="3">
          <v-btn
            color="blue-grey lighten-5"
            tile
            depressed
            block
            :disabled="loading || stepsForm === 1"
            @click="backStep"
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Regresar
          </v-btn>
        </v-col>
        <v-col cols="6" sm="4" md="3">
          <v-btn
            :color="stepsForm === 3 ? 'icono' : 'primary'"
            tile
            dark
            depressed
            block
            :loading="loading"
            :disabled="cli_not_facts"
            @click="nextStep"
          >
            <v-icon v-if="stepsForm === 3" left>mdi-check</v-icon>
            {{ stepsForm === 3 ? 'Confirmar' : 'Continuar' }}
            <v-icon v-if="stepsForm < 3" right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-overlay
      :value="loading || loadingFacts"
      color="white"
      absolute
      opacity=".6"
      class="text-center"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
      <h6 class="blue-grey--text mt-2">
        {{ loadingFacts ? 'Buscando Facturas' : 'Guardando' }}
      </h6>
    </v-overlay>
    </v-row>
  </v-navigation-drawer>
</template>
<script>
import moment from 'moment'
import { createCobro, getFacturas, infoCobro } from '@/services/cobros'
import { getBase64 } from '@/util/helpers'

const validateMonto= (value) => {
  return /^[0-9]+([,][0-9]+)?$/.test(String(value));
}

const cobroDefault = () => ({
  fact: [],
  co_ven: '',
  ref: '',
  co_cli: '',
  metodo_pago: '',
  moneda: '',
  cod_banco: '',
  fecha: '',
  tasa: '',
  observacion: '',
  fotos: [],
  monto: '',
  monto_bs: '',
})

const ErrorsDefault = () => ([{ valid: true }, { valid: true }])

export default {
  name: 'Registre',
   components:{
     FechaInput: () => import(
      /* webpackChunkName: "fecha-input" */
      '@/widgets/FechaInput.vue'
    ),
     ConfirmacionInfo: () => import(
      /* webpackChunkName: "confirmacion-info" */
      './ConfirmacionInfo.vue'
    ),
  },
  props:{
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      cobro: cobroDefault(),
      maxDate: moment().format("YYYY-MM-DD"),
      show: this.value,
      stepsForm: 1,
      inputFact: '',
      loading: false,
      loadInfo: false,
      loadingFacts: false,
      cli_not_facts: false,
      itemsFact: [],
      cli: [],
      bank: [],
      errorsForm: ErrorsDefault(),
    }
  },
  watch: {
    show (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.show = val
      this.$refs.COBRO_REGISTER_FORM_1.reset();
      this.$refs.COBRO_REGISTER_FORM_2.reset();
      this.cobro = cobroDefault()
      this.errorsForm = ErrorsDefault()
      this.inputFact = ''
      this.cli_not_facts = false
      this.itemsFact = []
      this.stepsForm = 1
    },
  },
  computed: {
    spacing () {
      return {
        'px-16': this.$vuetify.breakpoint.mdAndUp,
        'pa-8': this.$vuetify.breakpoint.smOnly,
      }
    },
    dialogRegister () {
      return {
        'transform-origin': 'center center',
        display: !this.show ? 'none' : '',
      }
    },
    itemsBancos () {
      return this.bank.length > 0
        ? this.bank.filter(b => b.moneda === this.cobro.moneda)
        : []
    },
    itemsClients () {
      return this.cli.length > 0
        ? this.cli.filter(c => c.inactivo === 0)
        : []
    },
    montoDolares () {
      return this.cobro.monto_bs !== '' && this.cobro.tasa !== '' && validateMonto(this.cobro.monto_bs) && validateMonto(this.cobro.tasa)
        ? (parseFloat(this.cobro.monto_bs.replace(',', '.')) / parseFloat(this.cobro.tasa.replace(',', '.'))).toFixed(2)
        : 0
    },
    descripcionSelect () {
      const CLIENTE = this.cli.filter(cli => cli.co_cli === this.cobro.co_cli)
      const BANCO = this.bank.filter(b => b.cod_banco === this.cobro.cod_banco)

      return {
        cli_des: CLIENTE.length > 0 ? CLIENTE[0].cli_des : '',
        banco_des: BANCO.length > 0 ? BANCO[0].nombre : '',
      }
    },
  },
  created () {
    this.getInfo()
  },
  methods: {
    async validateRegister () {
      if (this.stepsForm === 1) {
        const VALID_FORM_1 = await this.$refs.COBRO_REGISTER_FORM_1.validate()
        if (!VALID_FORM_1) return false
      }

      if (this.stepsForm === 2 ) {
        const IMAGE_EXT_VALID = this.cobro.fotos.map(item => item.ext).every(ext => ext === 'jpeg' || ext === 'jpg')
        const VALID_FORM_2 = await this.$refs.COBRO_REGISTER_FORM_2.validate()

        if (!VALID_FORM_2) return false

        if (this.cobro.fotos.length > 0 && !IMAGE_EXT_VALID) {
          this.$root.$showAlert(
            'El campo Fotos tiene un formato inválido. Formatos permitido JPEG/JPG',
            'error',
          )
          return false
        }
      }
      return true
    },
    async create () {
      let photosUpload = []
      for (var i = 0; i < this.cobro.fotos.length; i++) {
        const urlFile = await getBase64(this.cobro.fotos[i].file)
        photosUpload.push(urlFile)
      }
      this.loading = true
      try {
        const { message } = await createCobro({
          datos: {
            ...this.cobro,
            fotos: photosUpload,
            monto: this.cobro.moneda === 'dolares' ? this.cobro.monto : this.montoDolares,
          },
        })
        const [m_exito, nro] = message.split(',')
        this.$emit('created', nro)
        this.toBack()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getInfo () {
      this.loadInfo = true
      try {
        const { clientes, bancos } = await infoCobro()
        this.cli = clientes
        this.bank = bancos
      } catch (error) {
        console.error('Error al obtener la lista de Clientes')
      } finally {
        this.loadInfo = false
      }
    },
    async getFactClientes () {
      try {
        this.loadingFacts = true

        this.itemsFact = await getFacturas({ cliente: this.cobro.co_cli })

        if (this.itemsFact.length === 0) {
          this.cli_not_facts = true
        } else {
          this.cli_not_facts = false
        }
      } catch (error) {
        this.cli_not_facts = true
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener las factura de los Clientes.',
          'error',
        )
      } finally {
        this.loadingFacts = false
      }
    },
    async nextStep () {
      this.$vuetify.goTo(0)

      if(this.stepsForm === 3) {
        this.create()
        return
      }
      const VALIDATE = await this.validateRegister();
      this.errorsForm[`${this.stepsForm - 1}`].valid = VALIDATE
      if(VALIDATE) {
        this.stepsForm = this.stepsForm + 1
      }
    },
    backStep () {
      if(this.stepsForm === 1) {
        return
      }
      this.stepsForm = this.stepsForm - 1
    },
    fileDeleted(record) {
      this.$refs.FileImageCobro.deleteFileRecord(record)
    },
    toBack () {
      this.show = false
    },
    changeMoneda () {
      this.cobro.monto = ''
      this.cobro.monto_bs = ''
      this.cobro.tasa = ''
    },
    changeMetodo () {
      this.cobro.cod_banco = ''
      this.cobro.ref = ''
    },
  }
}
</script>
<style>
.full-opacity::before {
  opacity: 1 !important;
}

.full-opacity .v-btn__content {
  color: white !important;
}

.line-input {
  color: #333;
  border-color: rgba(0, 0, 0, 0.18) !important;
  background-color: #fafafa !important;
}

.line-input.v-chip.v-chip--outlined.v-chip.v-chip {
  background-color: #fafafa !important;
}

.line-dashed {
  border-style: dashed !important;
}

.input-multiple.v-text-field .v-input__control > .v-input__slot {
  flex-wrap: wrap !important;
}

.chip-status {
  height: 42px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.status.v-chip-group .v-slide-group__content {
    padding: 0 !important;
}

.width-chip {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
    max-width: 49%;
}

.toggle-registre {
  padding: 3px;
}

.wrap {
  white-space: normal !important;
}

.step-item .v-stepper__wrapper {
  overflow: visible !important;
}

.steps-header {
  height: 52px !important;
}

.grey .v-stepper__step--active .v-stepper__label {
  font-weight: bold;
}

</style>
